import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import AboutContent from '../components/About'
import SEO from '../components/SEO'

export default function About({ location, data }) {
  const { strapiAboutPage } = data
  const {
    meta_description: metaDescription,
    meta_keywords: metaKeywords,
  } = strapiAboutPage

  const meta = []

  if (metaKeywords) {
    meta.push({ name: 'keywords', content: metaKeywords })
  }

  return (
    <Layout location={location} project={{ ...strapiAboutPage, isAbout: true }}>
      <SEO title="About Us" description={metaDescription} meta={meta} />
      <Layout.Content>
        <AboutContent data={strapiAboutPage} />
      </Layout.Content>
    </Layout>
  )
}

About.propTypes = {
  data: PropTypes.shape({
    strapiAboutPage: PropTypes.shape({
      meta_description: PropTypes.string,
      meta_keywords: PropTypes.string,
    }),
  }).isRequired,
}

export const aboutFragment = graphql`
  fragment About on StrapiAboutPage {
    slug
    meta_description
    meta_keywords
    fields {
      aboutHTML
      contactHTML
      contactRightHTML
    }
    Clients {
      name
      id
    }
    People {
      bio
      id
      name
      title
      photo {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    address
    hide_street_view
    slides {
      id
      vimeo_url
      width
      height
      image_url
      image {
        extension
        childImageSharp {
          fluid(maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`

export const query = graphql`
  query($slug: String!) {
    strapiAboutPage(slug: { eq: $slug }) {
      ...About
    }
  }
`
